import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit"
import update from "immutability-helper";
import {FlashService} from "../../flash/flash-service";
import {Container} from "aurelia-dependency-injection";

const assignAdapter = createEntityAdapter({
    // selectId: ({id, modelId}) => modelId + ":" + id
})

function calculateRest(state) {
    state.rest = Array.from(Object.values(state.entities))
        .reduce(
            ({amount, currency}, {prices}) => {
                let sum = 0;

                for (let price of prices) {
                    sum += price.amount ?? 0;
                }

                return {amount: amount - sum, currency};
            },
            state.unassignedAmount
        )
}

const assignSlice = createSlice({
    name: "banking/assign",
    initialState: assignAdapter.getInitialState({unassignedAmount: {amount: 0, currency: "EUR"}}),
    reducers: {
        addPrice: {
            prepare: (id, prices) => ({payload: {id, prices}}),
            reducer(state, {payload: {id, prices}}) {
                prices = update(prices, {$push: [{}]});
                assignAdapter.updateOne(state, {id, changes: {prices}})
                calculateRest(state)
            }
        },
        removePrice: {
            prepare: (id, prices, index) => ({payload: {id, prices, index}}),
            reducer(state, {payload: {id, prices, index}}) {
                prices = update(prices, {$splice: [[index, 1]]});
                assignAdapter.updateOne(state, {id, changes: {prices}})
                calculateRest(state)
            }
        },
        setPrice: {
            prepare: (id, prices, index, price) => ({payload: {id, prices, index, price}}),
            reducer(state, {payload: {id, prices, index, price}}) {
                prices = update(prices, {[index]: {$set: price}});
                assignAdapter.updateOne(state, {id, changes: {prices}})
                calculateRest(state)
            }
        },
        clear: {
            prepare: ({unassignedAmount}) => ({payload: {unassignedAmount}}),
            reducer(state, {payload: {unassignedAmount}}) {
                state.unassignedAmount = unassignedAmount
                assignAdapter.removeAll(state)
                calculateRest(state)
            }
        },
        addAssignments: {
            prepare: items => ({payload: {items}}),
            reducer(state, {payload: {items}}) {

                const allowedCurrencies = [];

                if (state.rest && state.rest.currency) {
                    allowedCurrencies.push(state.rest.currency);
                }

                for (let {
                    id, modelId, openAmount,
                    orderNumber, paymentReceiver,
                    receiptNumber,
                    invoiceNumber, openPayments,
                    code, name,
                } of items) {

                    //Skip orders without payments yet
                    if ("accounting/ledger-account" !== modelId && null == openAmount) {
                        continue;
                    }

                    let label = [];
                    let openAmounts = [];

                    switch (modelId) {
                        case "order/order":
                            label.push(orderNumber + (paymentReceiver != null ? '; ' + (paymentReceiver.label ?? paymentReceiver.name) : ''));
                            break

                        case "receipt/receipt":
                            label.push(receiptNumber + (paymentReceiver != null ? '; ' + (paymentReceiver.label ?? paymentReceiver.name) : ''))
                            break

                        case "order-commission/invoice":
                            label.push(invoiceNumber + (paymentReceiver != null ? '; ' + (paymentReceiver.label ?? paymentReceiver.name) : ''))
                            break

                        case "accounting/ledger-account":
                            label.push(code + ': ' + name)
                            break
                    }

                    for (let i = 0; i < openPayments.length; i++) {
                        if (i > 0) {
                            label.push('');
                        }

                        if (allowedCurrencies.indexOf(openPayments[i].amount.currency) === -1) {
                            const flashService = Container.instance.get(FlashService);

                            flashService.error('Währung der Transaktion muss identisch sein mit den offenen Zahlungen');
                            return;
                        }

                        openAmounts.push(openPayments[i].label);
                    }

                    //Always assign rest, so we do not over assign by default
                    let price = state.rest;

                    openAmounts = openAmounts.join('<br>');
                    label = label.join('<br>');

                    assignAdapter.addOne(state, ({id, modelId, openAmounts, label, prices: [price]}))

                    calculateRest(state)
                }
            }
        },
        removeAssignment: {
            prepare: id => ({payload: {id}}),
            reducer(state, {payload: {id}}) {
                assignAdapter.removeOne(state, id)
                calculateRest(state)
            }
        },
    },
})

const assign = assignSlice.reducer
export default assign

export const {
    addPrice,
    removePrice,
    setPrice,
    clear,
    addAssignments,
    removeAssignment,
} = assignSlice.actions

const selectAssign = createSelector(state => state.banking, ({assign}) => assign)

export const {
    /** @type {(state: any) => Assignment[]} */
    selectAll: selectAllAssignments,
    /** @type {(state: any, id: string) => Assignment} */
    selectById: selectAssignmentById,
    selectIds: selectAssignmentIds,
    selectTotal: selectAssignmentsTotal
} = assignAdapter.getSelectors(selectAssign)

export const selectRest = createSelector(selectAssign, ({rest}) => rest)
export const selectSaveBody = createSelector(
    selectAllAssignments,
    assignments => {
        const mappedAssignments = [];

        for (const {id, modelId, prices} of assignments) {
            for (const amount of prices ?? []) {
                if (amount.amount != null) {
                    mappedAssignments.push({
                        ["accounting/ledger-account" === modelId ? "account" : "reference"]: {id, modelId},
                        amount,
                    })
                }
            }
        }

        return {
            "reference-group": mappedAssignments
        };
    }
)
