import {inject} from "aurelia-framework";
import {OperationInterface} from "./operations/operation.interface";
import {AppendToFieldValuesOperation} from "./operations/append-to-field-values.operation";
import {SetFieldValueOperation} from "./operations/set-field-value.operation";
import {AddToExistingMediaBundle} from "./operations/add-to-existing-media-bundle.operation";
import {TableRowActionsOperation} from "./operations/table-row-actions.operation.js";

@inject(
    AppendToFieldValuesOperation,
    SetFieldValueOperation,
    AddToExistingMediaBundle,
    TableRowActionsOperation
)
export class BulkOperationsRepository
{
    /** @var OperationInterface[] */
    operations;

    constructor(
        appendToFieldValuesOperation,
        setFieldValueOperation,
        addToExistingMediaBundle,
        tableRowActionsOperation
    ) {
        this.operations = {};

        this.addOperation(appendToFieldValuesOperation);
        this.addOperation(setFieldValueOperation);
        this.addOperation(addToExistingMediaBundle);
        this.addOperation(tableRowActionsOperation);
    }

    /*private*/ addOperation(operation)
    {
        this.operations[operation.getName()] = operation;
    }

    /*public*/ getOperations()
    {
        return this.operations.slice(0);
    }

    /*public*/ getOperation(operationName)
    {
        if (undefined === this.operations[operationName]) {
            throw new Error(`Operation "${operationName}" is not registered in OperationsRepository`);
        }

        return this.operations[operationName];
    }

    /*public*/ execute(operationName, context)
    {
        if (undefined === this.operations[operationName]) {
            throw new Error(`Operation "${operationName}" is not registered in OperationsRepository`);
        }

        return this.operations[operationName].execute(context);
    }
}
