import {bindable, customElement} from "aurelia-framework";
import jss from "jss";

const styles = {
    table: {
        textAlign: "right",
        "& th:nth-child(3), & td:nth-child(3)": {
            textAlign: "left"
        },
        "& th:nth-child(4), & td:nth-child(4)": {
            backgroundColor: "#e0e0e0",
            fontWeight: "bolder"
        },
        "& th:nth-child(5), & td:nth-child(5)": {
            color: "#555",
            fontSize: "95%"
        },
        "& tbody th[colspan]": {
            textAlign: "left",
            backgroundColor: "#c0c0c0"
        },
        "& tfoot th": {
            borderTop: "4px double #999999"
        }
    }
};

@customElement('sio-accounting-vat-payment')
export class VatPayment {

    @bindable payment;

    attached() {
        const {classes: {table}} = jss.createStyleSheet(styles).attach();
        this.tableClass = table;
    }

    bind() {
        const groups = new Map();

        for (const field of this.payment.fields) {
            if (field.payAmount?.amount) {
                if (this.total?.amount) {
                    this.total.amount += field.payAmount.amount;
                } else {
                    this.total = Object.assign({}, field.payAmount);
                }
            }
            groups.set(field.group, (groups.get(field.group) ?? []).concat(field));
        }

        groups.forEach(fields => fields.sort((a, b) => 100 * (a.line - b.line) * 100 + a.code - b.code));
        this.groups = Array.from(groups.entries()).sort((a, b) => a[0].localeCompare(b[0]));
    }
}

export default VatPayment;
