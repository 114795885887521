import React, {useContext, useEffect} from "react"
import {useSelector} from "react-redux"
import {ButtonToolbar, IconButton, Loader, Table} from "rsuite"
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon"
import {useDispatch} from "../../../store/store";
import AureliaContext from "../../../utilities/aurelia-context";
import {useLoadEntityQuery} from "../../../store/api";
import {
    addPrice,
    clear,
    removeAssignment,
    removePrice,
    selectAllAssignments,
    selectRest,
    setPrice
} from "../../store/assign-slice"
import {useStyles} from "../../../costs/origin/constants";
import {CurrencyInput} from "../../../form/rsuite-form";
import LinkButtons from "./link-buttons";
import SaveButton from "./save-button";
import PlusIcon from '@rsuite/icons/Plus';
import MinusIcon from '@rsuite/icons/Minus';

export default function AssignForm({id, closeModal}) {
    const dispatch = useDispatch()
    const {i18n, currencyValueConverter} = useContext(AureliaContext)
    const {isLoading, data: transaction} = useLoadEntityQuery({
        id,
        modelId: "banking-api/transaction",
        queryString: "embeds[]=account",
    }, {refetchOnMountOrArgChange: true})

    const assignments = useSelector(selectAllAssignments)
    const rest = useSelector(selectRest)
    const {danger} = useStyles()

    useEffect(() => {
        if (transaction) {
            dispatch(clear(transaction))
        }
    }, [transaction]);

    if (isLoading) {
        return <Loader/>
    }

    const {
        unassignedAmount,
        purpose,
        counterpartName,
        counterpartIban,
        account: {organization: {id: organization}}
    } = transaction
    const title = `
        ${purpose} (${currencyValueConverter.toView(unassignedAmount)})<br>
        <small>${counterpartName} (IBAN ${counterpartIban})</small>
    `

    const originalPrices = [
        unassignedAmount
    ];

    if (transaction.bankAmount) {
        originalPrices.push(transaction.bankAmount);
    }

    return (
        <>
            <p dangerouslySetInnerHTML={{__html: title}}/>

            <LinkButtons title={title} organization={organization}/>

            <Table
                autoHeight
                data={[
                    {readonly: true, label: "zuzuordnen:", prices: originalPrices},
                    ...assignments,
                    {readonly: true, label: "Rest:", prices: [rest]},
                ]}
                rowHeight={rowData => {

                    if (rowData == null || rowData.label == null) {
                        return 48;
                    }

                    let labelHeight = ((rowData?.label.split('<br>').length) * 16) + 32;

                    let inputHeight = rowData?.prices.length * 28 + 32;

                    return Math.max(labelHeight, inputHeight);
                }}
                rowKey="id"
            >
                <Table.Column flexGrow={3}>
                    <Table.HeaderCell>
                        Referenz
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({label}) => <span dangerouslySetInnerHTML={{__html: label}}></span>}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={2} align="right">
                    <Table.HeaderCell>
                        <strong>{i18n.tr("sio.field.amount")}</strong>
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({id, prices, readonly}) => readonly ? (
                            prices.map((price, index) => (
                                <strong
                                    key={index}>{index > 0 ? ' / ' : ''}{currencyValueConverter.toView(price)}</strong>
                            ))
                        ) : (
                            prices.map((price, index) => (
                                <CurrencyInput
                                    key={index}
                                    size="sm"
                                    value={price}
                                    onChange={price => dispatch(setPrice(id, prices, index, price))}
                                />
                            ))
                        )}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} align="left">
                    <Table.HeaderCell>
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({id, prices, readonly}) => readonly ? <></> : (
                            prices.map((price, index) => {
                                return <ButtonToolbar>
                                    {index + 1 === prices.length ?
                                        <IconButton size="sm" icon={<PlusIcon/>} color={"green"}
                                                    onClick={() => dispatch(addPrice(id, prices))}></IconButton>
                                        : <IconButton size="sm" icon={<MinusIcon/>} color={"red"}
                                                      onClick={() => dispatch(removePrice(id, prices, index))}></IconButton>}
                                </ButtonToolbar>;
                            })
                        )}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={2} align="right">
                    <Table.HeaderCell>
                        {i18n.tr("sio.field.openAmount")}
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({openAmounts}) => <span dangerouslySetInnerHTML={{__html: openAmounts}}></span>}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={48}>
                    <Table.HeaderCell/>
                    <Table.Cell>
                        {({id, readonly}) => (!readonly) && (
                            <IconButton
                                className={danger}
                                size="xs"
                                icon={<SioIcon icon="fa fa-trash"/>}
                                onClick={() => dispatch(removeAssignment(id))}
                            />
                        )}
                    </Table.Cell>
                </Table.Column>
            </Table>

            <div className="text-right" style={{marginTop: "1rem"}}>
                <SaveButton id={id} closeModal={closeModal}/>
            </div>
        </>
    )
}
